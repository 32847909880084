import { Injectable } from '@angular/core';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import cloneDeep from 'lodash-es/cloneDeep';
import { Observable, Subject } from 'rxjs';
import { FirebaseApiService } from 'lib/services/firebase/firebase.api.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/firestore';
import { filter, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { IReview } from 'lib/models/reviews.model';

@Injectable({
	providedIn: 'root',
})
export class LeadWatcherService {
	newApptSubject$ = new Subject();
	newApptObs$: Observable<any>;
	leadWatcherFirstTime: number = 0;
	workRequests: any = {
		all: { data: [], notification: false },
		pending: { data: [], notification: false },
		hired: { data: [], notification: false },
		notHired: { data: [], notification: false },
		complete: { data: [], notification: false },
		leads: { data: [], notification: false },
		changed: false,
		selectedSlug: '',
	};
	appointments: { data: CalendarEvent[]; notification: boolean } = {
		data: [],
		notification: false,
	};
	tasksListener;
	workRequestsListener;
	apppointmentsListener;
	job$ = new Subject();
	_proTarg = false;
	_edit = false;
	cart: any[] = [];
	_showCart = false;
	alertsList: any[] = [];
	publicReviews: IReview[] = [];
	_viewPropDetails = '';
	_tab = 'Details';
	_loading = false;
	_showDash = false;

	constructor(
		private firebaseApi: FirebaseApiService,
		private store: AngularFirestore,
		private datepipe: DatePipe
	) {}

	async listenTaskChanges(userId, teamArray) {
		try {
			const workCollection = this.store.collection<any>('tasks');
			this.tasksListener = workCollection
				.snapshotChanges(['added', 'modified'])
				.subscribe(() =>
					this.getAndSortWorkRequests(userId, teamArray)
				);
		} catch (e) {
			throw new Error('Could not listen to task changes');
		}
	}

	async listenWorkRequestChanges(userId, teamArray) {
		try {
			const workCollection = this.store.collection<any>(
				'workRequests',
				(ref) =>
					ref
						.where(
							'potentialVendors',
							'array-contains-any',
							teamArray
						)
						.where('status', '==', 'finished')
						.orderBy('createdAt', 'desc')
			);
			this.workRequestsListener = workCollection
				.snapshotChanges(['added', 'modified'])
				.pipe(
					filter(
						(actions: any, index) =>
							index > 0 ||
							!actions.every(
								({
									payload: {
										doc: { metadata },
									},
								}) => metadata.fromCache
							)
					)
				)
				.pipe(
					map((actions) =>
						actions.map((a) => {
							const data = a.payload.doc.data();
							const id = a.payload.doc.id;
							return { id, ...data };
						})
					)
				)
				.subscribe(async (snaps: any) => {
					await this.getAndSortWorkRequests(userId, teamArray);
					snaps.forEach(async (snap) => {
						// removed css
					});
				});
		} catch (e) {
			throw new Error('Could not listen to work request changes');
		}
	}

	async listenAppointmentsChange(userId) {
		try {
			const workCollection = this.store.collection<any>('appointments');
			this.apppointmentsListener = workCollection
				.snapshotChanges()
				.subscribe(() => this.getAppointments(false, userId));
		} catch (e) {
			throw new Error('Could not listen to appointments changes');
		}
	}

	async getAppointments(firstTime: boolean, userId) {
		const cBefore = cloneDeep(this.appointments);
		const appointments = await this.firebaseApi.findAll(
			'appointments',
			(ref) =>
				ref.where('vendorId', '==', userId).orderBy('createdAt', 'desc')
		);
		const appts: any[] = [];
		if (appointments.length > 0) {
			await new Promise((resolve, reject) => {
				appointments.forEach(async (appt, index) => {
					try {
						appt.userInfo = await this.firebaseApi.findOneById(
							'users',
							appt.userId
						);
						appt.vendorInfo = await this.firebaseApi.findOneById(
							'users',
							appt.vendorId
						);
						appt.workRequest = this.workRequests.all.data.find(
							(wRA) => wRA.id === appt.workRequestId
						);
						if (
							appt.userInfo &&
							appt.vendorInfo &&
							appt.workRequest
						) {
							appts.push(appt);
						}
						if (index + 1 === appointments.length) resolve(true);
					} catch (e) {
						reject(e);
					}
				});
			});
			const actions: CalendarEventAction[] = [
				{
					label: '<span style="color:red;">Delete</span>',
					a11yLabel: 'Delete',
					onClick: async ({ event }: { event: CalendarEvent }) => {
						await this.firebaseApi.delete(
							'appointments',
							event.meta.id
						);
						const findIndex = this.appointments.data.findIndex(
							(a) => a.meta.id === event.meta.id
						);
						if (findIndex >= 0)
							this.appointments.data.splice(findIndex, 1);
					},
				},
			];
			this.appointments.data = appts.map((appt) => {
				const calendarEventToAdd: CalendarEvent = {
					start: new Date(appt.appointmentDate),
					end: new Date(appt.appointmentDateEnd),
					title: `A meeting between tax preparer ${
						appt.vendorInfo?.firstName
					} ${appt.vendorInfo?.lastName} and tax filer ${
						appt.userInfo?.firstName
					} ${appt.userInfo?.lastName} at ${this.datepipe.transform(
						appt.appointmentDate,
						'dd MMMM y, h:mm a'
					)}`,
					color: {
						primary: '#e3bc08',
						secondary: '#FDF1BA',
					},
					meta: appt,
					actions,
				};
				return calendarEventToAdd;
			});

			this.newApptSubject$.next('');
		} else this.appointments.data = [];

		const cAfter = cloneDeep(this.appointments);

		if (!firstTime) {
			if (cBefore.data.length < cAfter.data.length) {
				this.appointments.notification = true;
			}
		}
	}

	async getAndSortWorkRequests(userId: string, teamArray: any[]) {
		this._loading = true;
		try {
			const cBefore = cloneDeep(this.workRequests);
			const workRequestsUnsorted = await this.firebaseApi.findAll(
				'workRequests',
				(ref) =>
					ref
						.where(
							'potentialVendors',
							'array-contains-any',
							teamArray
						)
						.where('status', '==', 'finished')
						.orderBy('createdAt', 'desc')
			);

			const workRequests = workRequestsUnsorted.sort(
				(a, b) => b.payment?.created - a.payment?.created
			);

			console.log('2 workRequests ->', workRequests);

			const tasks = await this.firebaseApi.findAll('tasks');

			console.log('2 tasks ->', tasks);

			await new Promise((resolve, reject) => {
				if (workRequests.length > 0)
					workRequests.forEach(async (wR, index) => {
						try {
							wR.responded = tasks.filter(
								(t) => t.workRequestId === wR.id
							);
							wR.userInfo = await this.firebaseApi.findOneById(
								'users',
								wR.userId
							);

							if (wR.requestType === 'Personal Tax Return') {
								const { dueDate, diffDays } = getDueDates(wR);
								wR.dueDate = dueDate;
								wR.daysBeforeDue = diffDays;
							}

							if (this.workRequests?.all?.data?.length > 0) {
								const foundWR = this.workRequests.all.data.find(
									(oldWr) => oldWr.id === wR.id
								);
								if (
									foundWR &&
									foundWR.userDocs &&
									foundWR.userDocs.length < wR.userDocs.length
								) {
									wR.newDocument = true;
									wR.userDocs.forEach((doc) => {
										const findIndex =
											foundWR.userDocs.findIndex(
												(oldDoc) =>
													oldDoc.url === doc.url
											);
										if (findIndex < 0) {
											doc.new = true;
										}
									});
								}
							}

							if (index + 1 === workRequests.length)
								resolve(true);
						} catch (e) {
							reject(e);
						}
					});
				else resolve(true);
			});

			// hired job
			this.workRequests.hired.data = [
				...findHired(workRequests, userId, teamArray),
			];

			this.workRequests.hired.data.forEach((hired) => {
				// removed css
			});

			// pending job
			this.workRequests.pending.data = findPending(workRequests);

			this.workRequests.pending.data.forEach((pending) => {
				// removed css
			});

			// new lead jobs
			this.workRequests.leads.data = findLeads(workRequests);

			// not hired
			this.workRequests.notHired.data = findNotHired(
				workRequests,
				userId
			);

			// dompleted
			this.workRequests.complete.data = findComplete(
				workRequests,
				userId
			);

			//all data
			this.workRequests.all.data = [...workRequests];

			console.log('final leads -=>', this.workRequests);
			const cAfter = cloneDeep(this.workRequests);

			if (this.leadWatcherFirstTime >= 2) {
				if (cBefore.all.data.length < cAfter.all.data.length) {
					this.workRequests.all.notification = true;
					this.workRequests.changed = true;
				}

				if (cBefore.hired.data.length < cAfter.hired.data.length) {
					this.workRequests.hired.notification = true;
					this.workRequests.changed = true;
				}

				if (cBefore.pending.data.length < cAfter.pending.data.length) {
					this.workRequests.pending.notification = true;
					this.workRequests.changed = true;
				}

				if (
					cBefore.notHired.data.length < cAfter.notHired.data.length
				) {
					this.workRequests.notHired.notification = true;
					this.workRequests.changed = true;
				}

				if (cBefore.leads.data.length < cAfter.leads.data.length) {
					this.workRequests.leads.notification = true;
					this.workRequests.changed = true;
				}
			}

			this.leadWatcherFirstTime += 1;

			this.job$.next('');
			this._loading = false;

			function getDueDates(wR) {
				const usersExpectedDueDate = wR.questionnaire
					.find((q) => q.key === 'q17')
					.answers.find((a) => a.value === true);

				let dueDate: Date;
				let diffDays = 0;

				if (usersExpectedDueDate && usersExpectedDueDate.key) {
					switch (usersExpectedDueDate.key) {
						case 'rush-service':
							dueDate = new Date(
								new Date(wR.postedDate).getTime() +
									1000 * 60 * 60 * 12
							);
							break;
						case 'next-day':
							dueDate = new Date(
								new Date(wR.postedDate).getTime() +
									1000 * 60 * 60 * 24
							);
							break;
						case 'two-days':
							dueDate = new Date(
								new Date(wR.postedDate).getTime() +
									1000 * 60 * 60 * 24 * 2
							);
							break;
						case 'three-days':
							dueDate = new Date(
								new Date(wR.postedDate).getTime() +
									1000 * 60 * 60 * 24 * 3
							);
							break;
						case 'within-week':
							dueDate = new Date(
								new Date(wR.postedDate).getTime() +
									1000 * 60 * 60 * 24 * 7
							);
							break;
						case 'couple-week':
							dueDate = new Date(
								new Date(wR.postedDate).getTime() +
									1000 * 60 * 60 * 24 * 14
							);
							break;
					}

					const diff = Math.abs(
						new Date(wR.postedDate).getTime() - dueDate.getTime()
					);
					diffDays = Math.ceil(diff / (1000 * 3600 * 24));
				}
				return { dueDate, diffDays };
			}

			function findHired(wRs, uId, teamA) {
				return wRs.filter((wR) =>
					tasks.find(
						(t) =>
							t.workRequestId === wR.id &&
							t.hired === true &&
							(wR.progress === 'in-progress' ||
								wR.progress === 'canceled') &&
							teamArray.includes(t.vendorId)
					)
				);
			}

			function findPending(wRs) {
				const pending: any[] = [];
				wRs.forEach((wR) => {
					let anyoneElseHired = false;
					const submittedRequest = tasks.find(
						(t) => t.workRequestId === wR.id && t.hired === false
					);
					wR.tasks = tasks.filter((t) => t.workRequestId === wR.id);
					wR.potentialVendors.forEach((pV) => {
						if (!anyoneElseHired) {
							anyoneElseHired = tasks.find(
								(t) =>
									t.workRequestId === wR.id &&
									t.hired === true
							);
						}
					});

					if (
						submittedRequest &&
						!anyoneElseHired &&
						wR.tasks?.find((t) => teamArray.includes(t.vendorId))
					) {
						wR.currentStatus = 'Responded';
						pending.push(wR);
					}
				});
				return pending;
			}

			function findLeads(wRs) {
				const leads: any[] = [];
				workRequests.forEach((wR) => {
					let youHaveResponded = false;
					wR.tasks = tasks.filter((t) => t.workRequestId === wR.id);
					wR.potentialVendors.forEach((pV) => {
						if (!youHaveResponded) {
							youHaveResponded = tasks.find(
								(t) =>
									t.vendorId === pV &&
									t.workRequestId === wR.id &&
									teamArray.includes(t.vendorId)
							);
						}
					});
					if (
						!youHaveResponded &&
						(wR.progress === 'open' ||
							wR.progress === 'in-progress') &&
						wR.responded.length < 20
					) {
						wR.currentStatus = 'Lead';
						leads.push(wR);
					}
				});
				return leads;
			}

			function findNotHired(wRs, uId) {
				const notHired: any[] = [];
				wRs.forEach((wR) => {
					let youWereNotHired = false;
					wR.potentialVendors.forEach((pV) => {
						if (!youWereNotHired) {
							youWereNotHired = tasks.find(
								(t) =>
									t.hired === true &&
									t.vendorId === pV &&
									t.vendorId !== uId &&
									t.workRequestId === wR.id
							);
						}
					});
					if (youWereNotHired) {
						wR.hiredByTeamMate = wR.responded.find(
							(r) => teamArray.includes(r.vendorId) && r.hired
						);
						wR.notHired = true;
						wR.currentStatus = 'Not hired';
						notHired.push(wR);
					}
				});

				return notHired;
			}

			function findComplete(wRs, uId) {
				const complete: any[] = [];
				wRs.forEach((wR) => {
					if (wR.progress === 'complete') {
						wR.currentStatus = 'Complete';
						complete.push(wR);
					}
				});
				return complete;
			}
		} catch (e) {
			this._loading = false;
			console.error('getAndSortWorkRequests error -=>', e);
		}
	}

	thisWr(wrId: string) {
		let wr: any = {};
		if (
			this.workRequests?.all?.data &&
			this.workRequests?.all?.data.length > 0
		)
			wr = this.workRequests.all.data.find((wR: any) => wR.id === wrId);
		return wr && wr.id ? wr : {};
	}

	number2(nbr: number) {
		return Math.floor(nbr * 100) / 100;
	}
}
