<!-------------------------left menu----------------------->

<div
	id="dash-menu"
	[class.collapsed]="isCollapsed"
	(click)="userProvider.resetChat()"
>
	<section>
		<div class="item opn-cls" (click)="toggleSidebar()">
			<ion-button fill="clear">
				<ion-icon
					class="black f1-2"
					name="caret-forward-circle-outline"
					[style.transform]="
						isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)'
					"
					style="
						transition: transform 0.3s ease-in-out;
						margin-left: -0.6rem;
					"
				></ion-icon>
			</ion-button>
		</div>
		<div
			class="item"
			[routerLink]="['/dashboard']"
			routerLinkActive="activepage"
		>
			<section class="relative">
				<ion-icon
					name="home-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Dashboard</span>
			</section>
		</div>

		<div
			class="item"
			[routerLink]="['/work-requests']"
			routerLinkActive="activepage"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="create-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Work Request</span>
				<div
					*ngIf="this.leadWatcher.workRequests?.changed"
					class="notif"
				>
					<ion-icon
						style="margin: 0 auto; font-size: 1.15rem"
						name="notifications-outline"
					></ion-icon>
				</div>
			</section>
		</div>

		<div
			class="item relative"
			[routerLink]="['/messages']"
			routerLinkActive="activepage"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="mail-outline"
					style="font-size: 25px"
				></ion-icon>
				<!-- <div class="class notif-messages msg-alert2">
					
				</div> -->
				<div class="message">
					<div><span>Messages</span></div>
					<div class="unread">{{ userProvider.totalUnreadMsg }}</div>
				</div>
			</section>
		</div>

		<div
			class="item"
			[routerLink]="['/docs']"
			routerLinkActive="activepage"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="folder-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Documents</span>
			</section>
		</div>

		<div
			[routerLink]="['/lead-purchase-options']"
			routerLinkActive="activepage"
			*ngIf="userProvider.loggedInUser.approvedToWork"
			class="item"
		>
			<section class="relative">
				<ion-icon
					name="options-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Lead Purchase Options</span>
			</section>
		</div>

		<div
			routerLinkActive="activepage"
			*ngIf="userProvider.loggedInUser.approvedToWork"
			class="item"
			[routerLink]="['/message-template']"
		>
			<section class="relative">
				<ion-icon
					name="reader-outline"
					style="font-size: 25px"
				></ion-icon>

				<span>Message Template</span>
			</section>
		</div>
	</section>

	<section class="margin-bot-1">
		<div
			class="item"
			[routerLink]="['/my-profile']"
			routerLinkActive="activepage"
			style="padding: 35px 0"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="calendar-outline"
					style="font-size: 25px"
				></ion-icon>
				<div>
					<div><span>Profile Completion</span></div>
					<ion-progress-bar value="0.9"></ion-progress-bar>
					<div>
						<span style="font-size: 12px !important; color: #777777"
							>... Completed</span
						>
					</div>
				</div>
			</section>
		</div>

		<div
			class="item"
			[routerLink]="['/support']"
			routerLinkActive="activepage"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="help-circle-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Support</span>
			</section>
		</div>

		<div class="item" (click)="openHelp()" routerLinkActive="activepage">
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="information-circle-outline"
					style="font-size: 25px"
				>
				</ion-icon>
				<span>Help Center</span>
			</section>
		</div>

		<div class="item" (click)="logoutPrompt()" routerLinkActive="active">
			<section class="relative">
				<ion-icon
					name="log-out-outline"
					style="font-size: 25px; transform: rotate(180deg)"
				></ion-icon>
				<span>Logout</span>
			</section>
		</div>
	</section>
</div>
