import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UserProviderService } from 'lib-user/services/userProvider/user-provider.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
	title = 'nexgen-website';
	appLoaded: boolean = false;

	idleState = 'NOT_STARTED';
	countdown?: number = null;
	lastPing?: Date = null;

	public appPages = [
		{ title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
		{ title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
		{ title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
		{ title: 'Archived', url: '/folder/Archived', icon: 'archive' },
		{ title: 'Trash', url: '/folder/Trash', icon: 'trash' },
		{ title: 'Spam', url: '/folder/Spam', icon: 'warning' },
	];
	public labels = [
		'Family',
		'Friends',
		'Notes',
		'Work',
		'Travel',
		'Reminders',
	];

	constructor(
		private platform: Platform,
		private idle: Idle,
		private keepalive: Keepalive,
		private cd: ChangeDetectorRef,
		private userProvider: UserProviderService
	) {
		this.initializeApp();
	}

	initializeApp() {
		this.platform.ready().then(async () => {
			this.appLoaded = true;
			this.initIdle();
			this.reset();
		});
	}

	initIdle() {
		this.idle.setIdle(5);
		this.idle.setTimeout(150 * 60);
		this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		this.idle.onIdleStart.subscribe(async () => {
			this.idleState = 'IDLE';
			if (this.userProvider.loggedInUser.id)
				await this.userProvider.lastSeen(new Date());
			this.logIdle();
		});

		this.idle.onIdleEnd.subscribe(async () => {
			this.idleState = 'NOT_IDLE';
			if (this.userProvider.loggedInUser.id)
				await this.userProvider.lastSeen(new Date());
			this.countdown = null;
			this.cd.detectChanges();
			this.logIdle();
		});

		this.idle.onTimeout.subscribe(() => {
			this.idleState = 'TIMED_OUT';
			this.userProvider.logout();
		});

		this.idle.onTimeoutWarning.subscribe((seconds) => {
			this.countdown = seconds;
			this.logIdle(seconds);
		});

		this.keepalive.interval(15);
		this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
	}

	logIdle(sec?) {}

	reset() {
		this.idle.watch();
		this.idleState = 'NOT_IDLE';
		this.countdown = null;
		this.lastPing = null;
	}

	ngOnDestroy(): void {}
}
